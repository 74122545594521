html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0 auto;
  padding: 0;
  font-family: "Cairo", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  font-size: 18px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  :root {
    font-size: 12px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  :root {
    font-size: 14px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  :root {
    font-size: 16px;
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Fonts */

@font-face {
  font-family: "Cairo";
  src: url("./assets/fonts/Cairo-Light.woff2") format("woff2"),
    url("./assets/fonts/Cairo-Light.woff") format("woff"),
    url("./assets/fonts/Cairo-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Cairo";
  src: url("./assets/fonts/Cairo-Regular.woff2") format("woff2"),
    url("./assets/fonts/Cairo-Regular.woff") format("woff"),
    url("./assets/fonts/Cairo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Cairo";
  src: url("./assets/fonts/Cairo-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Cairo-SemiBold.woff") format("woff"),
    url("./assets/fonts/Cairo-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Cairo";
  src: url("./assets/fonts/Cairo-Bold.woff2") format("woff2"),
    url("./assets/fonts/Cairo-Bold.woff") format("woff"),
    url("./assets/fonts/Cairo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
